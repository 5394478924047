<template>
  <div>
    <van-action-sheet v-model="$store.state.showSku">
      <div class="mask" @click.stop="$store.commit('changeShowSku', false)">
        <div v-if="goodsInfo" class="sku" @click.stop="$store.commit('changeShowSku', true)">
          <div class="shutDown" @click.stop="shutDownChange">
            <img src="../../assets/images/closesku.png">
          </div>
          <!-- {{goodsInfo.skuImg}} -->
          <div v-if="goodsInfo" class="tp">
            <!-- <img :src="goodsInfo.skuImg" style="width:78px;height:78px;" /> -->

            <imgLoading class="goods-cover" :src="goodsInfo.skuImg" />

            <!-- <span  class="goods-cover" v-if="!goodsInfo.skuImg"></span>
            <img class="goods-cover" v-else :src="goodsInfo.skuImg" /> -->
            <div v-if="goodsStatus != 7" class="goods-price">
              <div class="pris">
                <span class="hjy">{{ labelText }}</span>
                <span class="spe-icon">¥</span>
                <span class="hjy-count">{{ goodsInfo.showPrice }}</span>

                <span class="gwj">官网价¥{{ goodsInfo.goodPrice }}</span>
              </div>
              <div class="guige">已选：{{ txt }} {{ goodsCount }}件</div>
            </div>

            <div v-else class="goods-price">
              <div class="pris">
                <span class="spe-icon">¥</span>
                <span class="hjy-count">{{
                  datainfo.buyType == '0'
                    ? goodsInfo.goodVipPrice
                    : goodsInfo.showPrice
                }}</span>

                <span class="gwj">官网价¥{{ goodsInfo.goodPrice }}</span>
              </div>
              <div class="guige">已选：{{ txt }} {{ goodsCount }}件</div>
            </div>
          </div>

          <!-- {{goodsInfo.saleList}} -->

          <div class="out">
            <div v-if="goodsInfo && goodsInfo.saleList" class="inner">
              <div
                v-for="(item, index) in (goodsInfo.saleList || [])"
                :key="index"
                class="status-box"
              >
                <div class="tits">{{ item.title }}</div>
                <!-- {{item.buttons}} -->

                <!-- 这里规格和颜色 有问题 -->
                <div class="status">
                  <span v-for="(ele, idx) in item.buttons" :key="idx">
                    <span
                      :class="skuids && skuids[index] && skuids[index][idx] ? 'box act' : 'box'"
                      @click="choseSku(index, idx, ele)"
                    >
                      {{ ele.text }}
                    </span>

                    <!-- <span :class="skuids[index][idx] ? 'box act' : 'box'"
                      @click="choseSku(index, idx)"
                      >{{ ele.text }}</span> -->
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="num-box">
            <div style="font-size: 12px">购买数量</div>
            <div v-if="goodsStatus != 8" class="handles">
              <div class="edge" @click="handleCount(false)">-</div>
              <div class="cou">{{ goodsCount }}</div>
              <div class="add" @click="handleCount(true)">+</div>
            </div>

            <div v-else class="handles">
              <div class="edge">-</div>
              <div class="cou">{{ goodsCount }}</div>
              <div class="add">+</div>
            </div>
          </div>

          <div class="code-txt">商品编号{{ goodsCode }}</div>
          <!-- v-if="getClientInfo()" -->
          <div v-if="!isLife && getClientInfo()">
            <div class="addShopCarbtn" @click="addShopCar">加入购物车</div>
          </div>

          <div v-else>
            <div
              v-if="goodsInfo && goodsInfo.isSale && goodsInfo.isSale.saleState === 0"
              class="SkuSoldOut"
            >
              热脱销 找相似
            </div>
            <div v-else>
              <div
                v-if="!this.$store.state.room_id && goodsStatus != 7"
                class="btn-box"
              >
                <div
                  v-if="$store.state.skuStatus === 'redemption'"
                  class="btn3"
                  @click="addShopCar"
                >
                  确定
                </div>
                <div
                  v-if="
                    !isLife && goodsInfo &&
                      goodsInfo.isMustBuy === 0 &&
                      goodsStatus != 8 &&
                      goodsStatus != 9 &&
                      !$store.state.skuStatus
                  "
                  class="btn1"
                  @click="addShopCar"
                >
                  加入购物车
                </div>

                <div
                  v-if="
                    goodsStatus != 8 &&
                      goodsInfo &&
                      goodsInfo.isUseCoupon == 0 &&
                      goodsStatus != 9 &&
                      !$store.state.skuStatus
                  "
                  :class="goodsInfo && goodsInfo.isMustBuy === 0 ? 'btn1 btn2' : 'btn3'"
                  @click="orderPay"
                >
                  立即购买
                </div>

                <div v-if="goodsStatus == 9" class="btn3" @click="orderPay">
                  立即购买
                </div>

                <div
                  v-if="goodsStatus != 8 && goodsInfo && goodsInfo.isUseCoupon == 1"
                  :class="goodsInfo && goodsInfo.isMustBuy === 0 ? 'btn1 btn2' : 'btn3'"
                  @click="getCouponBuyGoods"
                >
                  领券购买
                </div>

                <div v-if="goodsStatus == 8" class="goods_bottom">
                  <div
                    v-if="datainfo.isGroup == 1"
                    class="goods_btn_bottom"
                    @click="btnPlayGoods(1)"
                  >
                    我要开团
                  </div>
                  <div
                    v-if="datainfo.isGroup == 0"
                    class="goods_btn_bottom"
                    @click="btnPlayGoods(2)"
                  >
                    立即参团
                  </div>
                </div>
              </div>

              <div
                v-else-if="
                  (datainfo && datainfo.buyType == 0) ||
                    this.$store.state.room_id
                "
                class="btn-box"
              >
                <div class="btn3" @click="orderPay">立即购买</div>
              </div>

              <div
                v-else-if="datainfo && datainfo.buyType == 1"
                class="btn-box"
              >
                <div class="btn3" @click="goSpellGroup">发起拼团</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-action-sheet>

    <van-popup
      v-model="showPop"
      :style="{
        height: '29%',
        'border-radius': '5px',
        width: '80%',
        padding: '20px',
      }"
    >
      <div class="showPopBox">
        <h3>实名认证</h3>
        <p>根据海关清关要求需实名认证,请完成实名认证后购买</p>
        <div class="showPopBtn" @click="Certification">确认</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import { ActionSheet, Popup, Toast } from 'vant'
import { getClientInfo } from '@/utils/public.js'
import imgLoading from '@/component/imgLoading'
import { addressH5List } from '@/services/userApi'
import {
  skuChangeInfo,
  productInfo,
  addShopCar,
  getGoodsDetail,
  spellGroupOrderConfirm,
  getSkuDetails,
  getCouponBuyGoods
} from '@/services/goods.js'

import {
  getProductSkuDatail,
  assembleDetail,
  flashDetail
} from '@/services/api.js'

// getProductSkuDatail

Vue.use(Toast)
  .use(Popup)
  .use(ActionSheet)

export default {

  name: 'MiniCart',
  components: {
    imgLoading
  },
  props: {
    // goodsInfo: {
    //   // 商品详情数据
    //   type: Object,
    //   default: {},
    // },

    goodsStatus: {
      // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情；7：拼团详情
      type: Number,
      default: 1
    },
    datainfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      indexArr: [],
      showPop: false,
      activityId: '',
      goodsCount: 1,
      labelText: '会员价',
      skuId: '',
      skuids: [], // 规格选中样式处理
      sign: [], // 选中索引标记
      txt: '', // 选中的文案
      goodsInfo: null,
      goodsCode: '', // 商品编号
      addressInfo: null,
      isLife: null, // 仰生活参数
      type: 1
    }
  },
  watch: {
    '$store.state.showSku'(n, o) {
      //  if(this.goodsInfo&&this.goodsInfo.skuImg){
      //    this.goodsInfo.skuImg = ""
      //  }
      if (!this.datainfo) {
        return
      }
      if (n) {
        this.getDatail()
        // this.skuChangeInfo()
      } else {
        this.goodsInfo.skuImg = ''
      }
    },
    goodsCount(n, o) {
      const obj = this.$store.state.skuInfo
      obj.goodsCount = n
      this.$store.commit('changeSkuInfo', obj)
    }
  },
  created() {
    const { isLife, lifeOrder, timestamp, sign } = this.$route.query
    this.isLife = isLife
    this.lifeOrder = lifeOrder
    this.timestamp = timestamp
    this.sign = sign
    if (this.datainfo && this.$store.state.showSku) {
      this.getDatail()
    }
  },
  mounted() {
    // this.$store.commit("changeShowSku", false);
    // this.skuChangeInfo()
    this.getAddressList()
  },
  destroyed() {
    this.$store.commit('changeShowSku', false) // 初始化规格索引
    // this.$store.dispatch("wxConfigDefault", {});
  },
  methods: {
    getClientInfo() {
      return getClientInfo()
    },
    shutDownChange() {
      this.$store.commit('changeShowSku', false)
    },
    // 领券购买
    getCouponBuyGoods() {
      const body = {
        uid: window.localStorage.getItem('uid'),
        liveId: '',
        productId: this.datainfo.goodId,
        productSkuId: this.goodsCode,
        activityId: this.goodsInfo.activityId
      }

      getCouponBuyGoods(body).then((res) => {
        this.orderPay()
      })
    },
    // 参团开团按钮

    btnPlayGoods(type) {
      const areaId = JSON.parse(window.localStorage.getItem('addressItem'))

      const obj = {
        team_id: this.datainfo.id, // id  '774',//
        goodsId: this.goodsInfo.product_id, // 商品id '395608',
        product_sku_id: this.goodsCode, // '436936',
        uid: window.localStorage.getItem('uid'), // 用户id '1625104',
        productNum: 1, // 商品数量 '1',
        addressIds: areaId.areaIds || 0, // this.$route.query.addressIds,//地址ID '703576',
        type: type, // 1开团 2参团  '1',
        areaId: areaId.areaIds
      }
      this.type = type
      if (type == 2) {
        obj.found_id = this.goodsInfo.groupInfo.id // 团ID'0',
      }
      this.$router.push({
        path: '/placeOder',
        query: obj
      })
    },
    // 悦淘国际跳转认证
    Certification() {
      // this.goodsInfo.isIdentification===0
      this.$router.push('/Infoauthentication')
    },
    // 初始化
    initData(index, idxs) {
      if (
        this.goodsStatus == 1 ||
        this.goodsStatus == 2 ||
        this.goodsStatus == 7 ||
        this.goodsStatus == 8 ||
        this.goodsStatus == 9
      ) {
        //  this.setStores(0, 0)
        // 自营和秒杀才会初始化规格数据

        if (this.goodsStatus == 7) {
          this.isSale = 7
        } else if (this.goodsStatus == 8) {
          this.isSale = 8
        }

        this.indexArr.forEach((e, i) => {
          const mk = []
          this.goodsInfo.saleList[i].buttons.forEach((ele, idx) => {
            idx === e ? mk.push(true) : mk.push(false)
          })

          this.$set(this.skuids, i, mk)
          this.handleChose(e)
        })
      } else {
        this.isSale = 1
      }
    },

    orderPay() {
      if (this.goodsInfo.isRealNameState === 0) {
        this.showPop = true
      } else {
        // 立即购买
        let data
        if (this.goodsStatus == 7) {
          data = this.goodsInfo
        } else {
          data = this.goodsInfo
        }
        // let addressInfo = this.$store.state.addressInfo;
        const addressInfo = this.addressInfo
        if (!addressInfo || !addressInfo.addressId) {
          Toast('请先选择收货地址')
          return
        }
        if (this.goodsStatus == 1 && data.levelCantBuy > 0) {
          Toast(data.levelTips)
          return
        }
        if (
          this.goodsStatus == 1 &&
          data.isFree == 1 &&
          data.canSend == 0 &&
          data.isNeedUpgrade == 1
        ) {
          // 白拿商品，等级不够时的提示
          Toast('会员等级不满足，请去升级会员')
          return
        }
        const liveId = this.$store.state.live_id || this.$route.query.live_id
        const live_people_id =
          this.$route.query.live_people_id ||
          this.$route.query.live_mid ||
          this.$store.state.live_people_id ||
          '' // 主播ID
        const isVideoNumBuy = this.$route.query.isVideoNumBuy || 0
        const PromoterUid = this.$route.query.PromoterUid
        const { CShopId, cshop_id = '', isLife, lifeOrder, timestamp, sign } = this.$route.query

        this.$store.commit('changeShowSku', false)

        if (this.goodsStatus == 1 || this.goodsStatus == 7) {
          // 自营订单|| 拼团单独购买订单
          this.$router.push(
            '/placeOder?productId=' +
            data.product_id +
            '&product_sku_id=' +
            this.goodsCode +
            '&addressIds=' +
            addressInfo.addressId +
            '&areaId=' +
            addressInfo.areaIds +
            '&addressType=0&productType=0&productNum=' +
            this.goodsCount +
            '&goodsId=' +
            data.product_id +
            '&codeNumber=' +
            this.$store.state.codeNumber +
            '&roomId=' +
            this.$store.state.room_id +
            '&robot_send=' +
            this.$store.state.robot_send +
            '&live_id=' +
            liveId +
            '&live_people_id=' +
            live_people_id +
            '&chengzhangzhi=' +
            data.newShareScore.buy +
            '&isVideoNumBuy=' +
            isVideoNumBuy +
            '&PromoterUid=' +
            PromoterUid +
            '&CShopId=' +
            (CShopId || cshop_id) +
            (isLife ? '&isLife=' + isLife : '') +
            (timestamp ? '&timestamp=' + timestamp : '') +
            (sign ? '&sign=' + sign : '') +
            (lifeOrder ? '&lifeOrder=' + lifeOrder : '')
          )
        }
        if (this._props.goodsStatus == 2 || this.goodsStatus == 9) {
          // 秒杀订单
          this.$router.push(
            '/placeModer?product_id=' +
            data.product_id +
            '&product_sku_id=' +
            this.goodsCode +
            '&activityId=' +
            this.$route.query.activityId +
            '&productType=0&addressIds=' +
            addressInfo.addressId +
            '&areaId=' +
            addressInfo.areaIds +
            '&productNum=' +
            this.goodsCount +
            '&codeNumber=' +
            this.$store.state.codeNumber +
            '&roomId=' +
            this.$store.state.room_id +
            '&robot_send=' +
            this.$store.state.robot_send +
            '&live_id=' +
            liveId +
            '&live_people_id=' +
            live_people_id +
            '&chengzhangzhi=' +
            data.newShareScore.buy +
            '&CShopId=' +
            (CShopId || cshop_id) +
            (isLife ? '&isLife=' + isLife : '') +
            (lifeOrder ? '&lifeOrder=' + lifeOrder : '') +
            (timestamp ? '&timestamp=' + timestamp : '') +
            (sign ? '&sign=' + sign : '')
          )
        }
      }
    },

    handleCount(bol) {
      // 减：false 加：true
      if (bol) {
        if (this.goodsInfo.isSale.saleState === 1) {
          this.goodsCount++
        }
      } else {
        if (this.goodsInfo.isSale.saleState === 1) {
          if (this.goodsCount == 1) {
            Toast('不能再少了呦')
            return
          }
          this.goodsCount--
        }
      }
    },
    // 设置SKUstores本地参数
    setStores(sku) {
      const obj = {
        txt: this.txt,
        indexs: this.indexArr,
        sku: sku || this.goodsInfo.saleList[0].buttons[0].skuList[0],
        isSale: this.goodsInfo.isSale,
        goodsCount: this.goodsCount,
        activityId: this.goodsInfo.activityId,
        goodsId: this.goodsInfo.product_id
      }

      this.$store.commit('changeSkuInfo', obj)
    },
    choseSku(index, idxs, item) {
      this.indexArr.splice(index, 1, idxs)

      this.skuChangeInfo(item.skuList[0], index, idxs)
    },

    handleChose(idxs) {
      // 处理选中的规格，显示在‘已选’文案上
      const sign = []
      this.skuids.forEach((item, index) => {
        item.forEach((ele, idx) => {
          ele ? sign.push(idx) : null
        })
      })

      this.sign = sign
      let txt = ''
      sign.forEach((item, index) => {
        txt = txt + ' ' + this.goodsInfo.saleList[index].buttons[item].text
      })
      this.txt = txt

      // this.goodsCode = this.goodsInfo.saleList[0].buttons[idxs].skuList[this.sign[this.sign.length - 1]] || this.goodsInfo.saleList[0].buttons[idxs].skuList[0]
      this.handleSkuId() // 处理规格ID

      // if (this.$store.state.addressReady) {
      //   this.skuChangeGoodsInfo(idxs);
      // } else {
      //   setTimeout(() => {
      //     this.skuChangeGoodsInfo(idxs);
      //   }, 1000);
      // }
    },
    handleSkuId() {
      // 处理规格ID
      const Info = this.goodsInfo
      const mk1 = []
      // const mk2 = []
      let mkArr = []
      this.indexArr.forEach((e, i) => {
        mkArr = mkArr.concat(Info.saleList[i].buttons[e].skuList)
      })

      // arr1.filter( x => new Set(arr2).has(x)).filter( x => new Set(arr3).has(x));

      mkArr.sort()
      for (var i = 0; i < mkArr.length;) {
        // 按照属性判断属性合并列数
        var count = 0
        for (var j = i; j < mkArr.length; j++) {
          if (mkArr[i] == mkArr[j]) {
            count++
          }
        }
        mk1.push({
          plName: mkArr[i],
          count: count
        })
        i += count
      }

      let SkuStr = ''
      if (mk1.length === 1) {
        SkuStr = mk1[0].plName
      } else {
        SkuStr = mk1.filter((e) => {
          return e.count === 2
        })[0].plName
      }

      this.goodsCode = SkuStr

      this.setStores(SkuStr)
    },
    async addShopCar() {
      if (this.goodsInfo.isRealNameState === 0) {
        this.showPop = true
        return
      }

      // 加入购物车
      if (this.goodsStatus == 1 && this.goodsInfo.levelCantBuy > 0) {
        Toast(this.goodsInfo.levelTips)
        return
      }
      const shareId = localStorage.getItem('shareCodeNumber')
      const { cshop_id } = this.$route.query
      const data = {
        shareId,
        positionFrom: '0',
        productId: this.goodsInfo.product_id,
        productSkuId: this.goodsCode,
        uid: window.localStorage.getItem('uid') || '',
        productNum: this.goodsCount,
        joinLocation: 'collage',
        CShopId: cshop_id && cshop_id !== '' ? cshop_id : 0
        // activityId: this.goodinfo.activityId,
      }

      if (this.datainfo.activityId) {
        data.activityId = this.datainfo.activityId
      }

      if (this.datainfo.isCollage && this.datainfo.isCollage === 1) {
        data.isCollage = 1
      }

      if (this.$store.state.skuStatus === 'redemption') {
        data.isCollage = 1
        delete data.joinLocation
      }
      if (this.$route.query.room_id) {
        data.room_id = this.$route.query.room_id
      }
      if (getClientInfo()) {
        const mid = this.$route.query.mid
        data.uid = mid
      }

      addShopCar(data).then((res) => {
        if (Number(res.status) === 200 || Number(res.code) === 200) {
          Toast('加入购物车成功')
          if (this.goodsStatus == 1 || this.goodsStatus == 2) {
            // 刷新购物车数量
            // this.$parent.getGoodsDetail();
          }
          this.$emit('success', res.data)
          this.$store.commit('changeShowSku', false)

          this.$store.commit('changeShowSkuStatus', null)
          // this.goodsCount = 1;
          // this.initData();
        }
      })
    },
    // 发起拼团
    goSpellGroup() {
      const _this = this
      const addressInfo = _this.$store.state.addressInfo
      // 当前判断未进行判断地址是否删除并且直接进行使用报错！！！

      if (!(addressInfo && addressInfo.addressId)) {
        Toast('请选择地址')
        return
      }
      // 拼团订单确认
      const data = {
        team_id: _this.goodsInfo.assembleInfo.id, // id  '774',//
        goods_id: _this.goodsInfo.assembleInfo.goods_id, // 商品id '395608',
        sku_id: _this.goodsInfo.assembleInfo.sku_id, // '436936',
        uid: window.localStorage.getItem('uid'), // 用户id '1625104',
        goods_num: _this.goodsCount, // 商品数量 '1',
        area_id: addressInfo.addressId, // 地址ID '703576',
        type: _this.type, // 1开团2参团  '1',
        found_id: '0' // 团ID（开团没有，参团从分享地址中获取） '0',
      }
      spellGroupOrderConfirm(data).then((res) => {
        if (Number(res.code) === 200) {
          this.$store.state.showSku = false
          // 其他
          const { id, goods_id, sku_id } = _this.goodsInfo.assembleInfo
          const { addressId, areaIds } = _this.$store.state.addressInfo
          this.$router.push({
            path: '/placeOder',
            // path: "/SpellGroupDetail",
            query: {
              // ?team_id=710&goodsId=1592557&product_sku_id=9669997&type=1&found_id=0&areaId=1_72_4211_0&productNum=1&addressIds=1067845

              team_id: id,
              goods_id: goods_id,
              goodsId: goods_id,
              sku_id: sku_id,
              product_sku_id: sku_id,
              goods_num: _this.goodsCount,
              productNum: _this.goodsCount,
              area_id: addressId,
              areaId: areaIds,
              addressIds: addressId,
              productId: _this.goodsInfo.product_id,
              goodsCode: this.goodsCode,
              areaIds: areaIds,
              chengzhangzhi: _this.goodsInfo.newShareScore.buy,
              shareTitle: _this.goodsInfo.assembleInfo.share_title,
              shareDesc: _this.goodsInfo.assembleInfo.share_desc,
              shareImg: _this.goodsInfo.assembleInfo.share_img,
              type: _this.type,
              productType: this.goodsStatus,
              addressType: 0
            }
          })
        } else if (Number(res.code) === 400) {
          // 如果异常进入400，则需要提示mesage
          // 此处如果后期因为地址信息未从缓存中清除则需要后端配合新给出一个字段
          Toast(res.msg)
        }
      })
    },

    // 获取商品详情
    getDatail() {
      this.goodsInfo = null
      const shareId = localStorage.getItem('shareCodeNumber')
      if (this.goodsStatus == 7) {
        const data = {
          shareId,
          id: this.datainfo.id,
          uid: window.localStorage.getItem('uid') || '' // 用户ID
        }

        //  getProductSkuDatail

        getGoodsDetail(data).then((res) => {
          if (Number(res.code) === 200) {
            res.data.productInfo.id = res.data.assembleInfo.id
            res.data.productInfo.showPrice = res.data.assembleInfo.team_price
            res.data.productInfo.priceName = '单独购'
            res.data.productInfo.goodPrice = res.data.assembleInfo.origin_price
            res.data.productInfo.areaInfo = res.data.areaInfo
            res.data.productInfo.assembleInfo = res.data.assembleInfo
            res.data.productInfo.needer = res.data.assembleInfo.needer
            this.goodsInfo = res.data.productInfo

            const arr = []
            res.data.productInfo.saleList.forEach((e, i) => {
              arr.push(0)
            })
            this.indexArr = arr

            this.getProductSkuDatail(
              res.data.productInfo.saleList[0].buttons[0].skuList[0],
              0,
              0
            )

            // this.skuChangeInfo()
            // this.initData();
          }
        })
      } else if (this.goodsStatus == 8) {
        const data = {
          shareId,
          assembleId: this.datainfo.id, // 团活动ID
          uid: window.localStorage.getItem('uid') || '' // 用户ID
        }

        assembleDetail(data).then((res) => {
          if (Number(res.code) === 200) {
            const arr = []
            res.data.productInfo.saleList.forEach((e, i) => {
              arr.push(0)
            })

            res.data.productInfo.goodPrice = res.data.assembleInfo.origin_price
            res.data.productInfo.groupInfo = res.data.groupInfo
            res.data.productInfo.showPrice = res.data.assembleInfo.team_price
            res.data.productInfo.assembleInfo = res.data.assembleInfo
            this.goodsInfo = res.data.productInfo

            if (this.$store.state.skuInfo) {
              this.getSkuDetails(
                this.$store.state.skuInfo.sku,
                this.$store.state.skuInfo.pIndex,
                this.$store.state.skuInfo.skuIdx
              )
            } else {
              this.getSkuDetails(res.data.assembleInfo.sku_id, 0, 0)
            }
          }
        })
      } else if (this.goodsStatus == 2 || this.goodsStatus == 9) {
        const data = {
          goodsId: this.datainfo.goodId, // 产品ID
          skuId: this.datainfo.skuId,
          shareId,
          activityId: this.datainfo.activityId || 0,
          uid: window.localStorage.getItem('uid') || 0 // 用户ID
        }

        flashDetail(data).then((res) => {
          if (Number(res.code) === 200) {
            const arr = []
            res.productInfo.saleList.forEach((e, i) => {
              arr.push(0)
            })

            res.productInfo.goodPrice = res.FlashGoodsInfo.hiddenPrice
            res.productInfo.FlashGoodsInfo = res.FlashGoodsInfo
            res.productInfo.showPrice = res.FlashGoodsInfo.showPrice
            res.productInfo.areaInfo = res.areaInfo
            res.productInfo.identityType = res.identityType
            res.productInfo.isRealNameState = res.isRealNameState
            this.goodsInfo = res.productInfo

            if (this.$store.state.skuInfo) {
              this.indexArr = this.$store.state.skuInfo.indexs
              this.getProductSkuDatail(
                this.$store.state.skuInfo.sku,
                this.$store.state.skuInfo.pIndex,
                this.$store.state.skuInfo.skuIdx
              )
            } else {
              this.indexArr = arr
              this.getProductSkuDatail(
                res.productInfo.saleList[0].buttons[0].skuList[0],
                0,
                0
              )
            }
          }
        })
      } else {
        const body = {
          uid: window.localStorage.getItem('uid') || 0,
          product_id: this.datainfo.goodId,
          shareId,
          live_id: 0,
          liveId: 0,
          live_people_id: 0,
          product_sku_id: this.datainfo.skuId,
          productType: 0,
          room_id: 0,
          share_from: 0
        }
        if (this.datainfo.isChangeGiftGoods) {
          body.isChangeGiftGoods = 1
        }
        // skuChangeInfo
        productInfo(body).then((res) => {
          if (Number(res.code) === 200) {
            const arr = []
            res.data.saleList.forEach((e, i) => {
              arr.push(0)
            })
            this.goodsInfo = res.data
            if (
              this.$store.state.skuInfo &&
              this.$store.state.skuInfo.goodsId === res.data.product_id
            ) {
              this.indexArr = this.$store.state.skuInfo.indexs
              this.skuChangeInfo(
                this.$store.state.skuInfo.sku,
                this.$store.state.skuInfo.pIndex,
                this.$store.state.skuInfo.skuIdx
              )
            } else {
              this.indexArr = arr
              this.goodsCode = arr.length <= 0 ? this.$route.query.skuid : this.goodsCode
              this.skuChangeInfo(this.datainfo.skuId, 0, 0)
            }
          }
        })
      }
    },
    skuChangeGoodsInfo(idxs) {
    },
    // 开团SKUINFO
    getSkuDetails(skuId, index, idxs) {
      const data = {
        addressCode: this.$store.state.addressInfo.areaIds || 0,
        uid: window.localStorage.getItem('uid') || '',
        productSkuId: skuId
      }
      getSkuDetails(data).then((res) => {
        if (Number(res.code) === 200) {
          // 请求成功 显示出来
          if (res.data.isSale.saleState === 0) {
            this.goodsCount = 0
          } else if (res.data.isSale.saleState === 1) {
            this.goodsCount = 1
          }
          this.goodsInfo.isSale = res.data.isSale
          this.goodsInfo.skuImg = res.data.skuImg
          // this.setStores(index, idxs);
          this.initData(index, idxs)
          // this.dataObj =res.data;
        }
      })
    },
    // 拼团SKUINFO
    getProductSkuDatail(sku, index, idxs) {
      const shareId = localStorage.getItem('shareCodeNumber')
      const body = {
        shareId,
        uid: window.localStorage.getItem('uid') || '',
        product_sku_id: sku,
        addressCode: this.$store.state.addressInfo.areaIds || 0
      }

      getProductSkuDatail(body).then((res) => {
        if (res.data.isSale.saleState === 0) {
          this.goodsCount = 0
        } else if (res.data.isSale.saleState === 1) {
          this.goodsCount = 1
        }
        this.goodsInfo.isSale = res.data.isSale
        this.goodsInfo.skuImg = res.data.img
        // this.setStores(index, idxs);
        // this.goodsInfo.saleList = res.data.saleList;
        this.initData(index, idxs)
      })
    },
    // 切换规格重新设置商品
    skuChangeInfo(sku, index, idxs) {
      // this.datainfo.skuId

      const shareId = localStorage.getItem('shareCodeNumber')
      const body = {
        shareId,
        uid: window.localStorage.getItem('uid') || 0,
        productSkuId: sku,
        productType: this.goodsStatus,
        liveId: this.$route.query.liveid || 0,
        addressCode: this.$store.state.addressInfo.areaIds || 0,
        lon: 0,
        lat: 0
        // activityId:this.$route.query.activityId,
      }
      if (this.datainfo.isChangeGiftGoods) {
        body.isChangeGiftGoods = 1
      }
      skuChangeInfo(body).then((res) => {
        if (Number(res.code) === 200) {
          if (res.data.isSale.saleState === 0) {
            this.goodsCount = 0
          } else if (res.data.isSale.saleState === 1) {
            if (this.$store.state.skuInfo) {
              if (this.$store.state.skuInfo.goodsCount > 0) {
                this.goodsCount = this.$store.state.skuInfo.goodsCount
              } else {
                this.goodsCount = 1
              }
            } else {
              this.goodsCount = 1
            }
          }

          this.goodsInfo.isSale = res.data.isSale
          this.goodsInfo.skuImg = res.data.skuImg

          this.goodsInfo.showPrice = res.data.vipPrice
          this.initData(index, idxs)
        }
      })
    },

    getAddressList() {
      // 先从本地中读取，如果本地没有，去地址列表拿第一条

      if (window.localStorage.getItem('addressItem')) {
        // 选择地址后的操作
        const data = JSON.parse(window.localStorage.getItem('addressItem'))
        this.addressInfo = data
        this.$store.commit('handleAddressInfo', data)
        this.$store.commit('changeAddressReady', true)
        return
      }

      // 获取默认地址
      const data = {
        mid: window.localStorage.getItem('uid')
      }
      if (!data.mid) {
        return
      }
      addressH5List(data).then((res) => {
        if (Number(res.status) === 200) {
          res.data = res.data || []
          if (res.data.length > 0) {
            let isDefault = 0
            res.data.forEach((val) => {
              isDefault += val.isDefault
              if (val.isDefault == 1) {
                this.addressInfo = val
              }
            })
            if (isDefault > 0) {
              this.$store.commit('handleAddressInfo', this.addressInfo)
            } else {
              this.addressInfo = res.data[0]
              this.$store.commit('handleAddressInfo', res.data[0])
            }
          }
        }
        this.$store.commit('changeAddressReady', true)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.van-sku-confirm {
  width: 80%;
  height: 40px;
  color: #fff;
  background: #e43f44;
  line-height: 40px;
  margin: 0 auto 10px auto;
  font-size: 14px;
  border-radius: 20px;
}

/deep/ .van-sku-header-item {
  text-align: left !important;
  // display: none;
}

/deep/ .van-sku-row {
  text-align: left;

  /deep/ .van-sku-row__title {
    font-weight: bold;
  }
}

/deep/ .van-sku__stepper-title {
  font-weight: bold;
}

/deep/ .van-sku-row__item {
  border: 1px solid #f7f8fa;
  border-radius: 20px !important;
}

/deep/ .van-sku-row__item--active {
  border: 1px solid red;
}

/deep/ .van-sku-row__item-name {
  font-weight: bold;
  padding: 3px 5px;
}

.van-sku-selectNum {
}

.van-sku__goods-price {
  text-align: left;
  font-weight: bold;

  .van-sku-oldPrice {
    margin-left: 10px;
    color: #666666;
  }
}

.mask {
  // position: fixed;
  // top: 0;
  // bottom: 0;
  // left: 0;
  // top: 0;
  width: 100%;
  height: 100%;
  // background: rgba(0, 0, 0, 0.75);
  z-index: 2;

  .picture-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .picture {
      display: block;
      width: 280px;
      // height: 360px;
      background: rebeccapurple;
      margin: 0 auto;
    }

    .btn {
      width: 290px;
      height: 44px;
      border-radius: 22px;
      background: #ed2e2e;
      text-align: center;
      line-height: 44px;
      font-size: 16px;
      color: #fff;
      margin-top: 15px;
    }
  }

  .sku {
    position: relative;
    width: 100%;
    min-height: 500px;
    border-radius: 8px 8px 0 0;
    background: #fff;
    // position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 14px;
    z-index: 9998;

    .shutDown {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: 20px;
      // background: #000;
      z-index: 9998;
    }

    .tp {
      display: flex;
      width: 100%;
      position: relative;
      margin-bottom: 20px;

      .goods-cover {
        width: 78px;
        height: 78px;
        overflow: hidden;
        border-radius: 10px;

        img {
          border-radius: 14px;
          width: 78px;
          height: 78px;
        }

        background: #e0e0e0;
      }

      .goods-price {
        width: 250px;
        height: 78px;
        display: flex;
        // align-items: center;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: border-box;
        padding-left: 12px;

        .pris {
          text-align: left;

          .hjy {
            color: #ed2e2e;
            font-size: 13px;
          }

          .hjy-count {
            color: #ed2e2e;
            font-size: 18px;
            font-weight: bold;
            margin-right: 12px;
          }

          .spe-icon {
            font-size: 14px;
            color: #ed2e2e;
            font-weight: bold;
            margin-left: 4px;
          }

          .gwj {
            color: #999;
            text-decoration: line-through;
            font-size: 13px;
          }
        }

        .guige {
          text-align: left;
          font-size: 12px;
          color: #545252;
          font-weight: bold;
        }

        .close {
          width: 16px;
          height: 16px;
          background: url("../../assets/images/closesku.png") no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 4px;
          top: 4px;
        }
      }
    }

    .out {
      height: 200px;
      overflow: hidden;
      margin-bottom: 50px;

      .inner {
        height: 200px;
        overflow: auto;
      }
    }

    .status-box {
      width: 100%;
      margin-bottom: 8px;

      .tits {
        font-size: 12px;
        color: #141f33;
        margin-bottom: 12px;
        text-align: left;
      }

      .status {
        width: 100%;
        overflow: hidden;

        .box {
          width: auto;
          padding: 6px 10px;
          background: #f0f2f5;
          border-radius: 4px;
          font-size: 12px;
          color: #141414;
          display: inline-block;
          margin-right: 8px;
          margin-bottom: 8px;
          float: left;
          border: 1px solid #f0f2f5;
        }

        .act {
          background: #fae9e8;
          color: #ed2e2e;
          border: 1px solid red;
        }
      }
    }
  }

  .num-box {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    color: #141f33;
    margin-bottom: 23px;

    .handles {
      .edge,
      .add {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
      }

      .cou {
        display: inline-block;
        width: 45px;
        height: 28px;
        background: #f0f2f5;
        border-radius: 4px;
        color: #616b80;
        font-size: 12px;
        line-height: 28px;
        margin: 0 4px;
      }
    }
  }

  .code-txt {
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #99a0ad;
    margin-bottom: 4px;
  }

  .btn-box {
    width: 100%;
    height: 44px;
    border-radius: 22px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .addShopCar {
      width: 80%;
      height: 40px;
      color: #fff;
      background: #e43f44;
      line-height: 40px;
      margin: 0 auto 10px auto;
      font-size: 14px;
      border-radius: 20px;
    }

    .btn1 {
      flex: 1;
      height: 100%;
      line-height: 44px;
      background: #201e1d;
      color: #fff;
      font-size: 16px;
    }

    .btn2 {
      background: #ff001c;
    }

    .btn3 {
      width: 100%;
      height: 100%;
      line-height: 44px;
      color: #fff;
      font-size: 16px;
      background: #ff001c;
    }
  }
}

// /deep/.van-action-sheet{
// min-height: 80%;
// }
// /deep/.van-popup--bottom{
//   height:80%;
// // top:25%;
// }

.showPopBox {
  //  width: 270px;
  //  height: 150px;
  // border-radius:20px;
  // padding:10px ;
  h3 {
    width: 100%;
    margin-bottom: 20px;
    color: #000;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
  }

  p {
    font-size: 12px;
    width: 100%;
    text-align: center;
    line-height: 22px;
  }

  .showPopBtn {
    width: 90%;
    color: #fff;
    font-size: 14px;
    text-align: center;
    height: 30px;
    background: #ff001c;
    border-radius: 15px;
    line-height: 30px;
    margin: 20px auto;
  }
}

.goods_bottom {
  height: 70px;
  width: 100%;
  font-size: 10px;
  position: absolute;
  bottom: 0px;
  color: #999;

  .goods_btn_bottom {
    width: 90%;
    text-align: center;
    position: absolute;
    bottom: 12px;
    border-radius: 20px;
    color: #fff;
    line-height: 40px;
    background: red;
    left: 50%;
    transform: translate(-50%);
    font-size: 16px;
  }
}

.SkuSoldOut {
  width: 100%;
  height: 100%;
  line-height: 44px;
  color: #fff;
  font-size: 16px;
  background: #999;
  border-radius: 22px;
}

.addShopCarbtn {
  width: 80%;
  height: 100%;
  line-height: 44px;
  background: #201e1d;
  color: #fff;
  font-size: 16px;
  margin: 0 auto;
  border-radius: 22px;
}
</style>
